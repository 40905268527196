/* Tooltip container */
.toolstip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.toolstip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: gray;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -50px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.toolstip:hover .tooltiptext {
  visibility: visible;
}

.card-header {
  background-color: #b12828 !important;
  color: #fff;
  font-weight: bold;
}
