.cardHeader {
  display: flex;
  flex-flow: column;
  /* background-color: #ccc !important; */
  width: 100%;
}

.cardBody {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.headerInput {
  margin: 0.5rem 0;
  border: 1px solid #777 !important;
}

.ringSelectionCard {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .ringSelectionCard {
    max-width: 1200px;
  }
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  width: 2px;
}

.radio-toolbar label {
  height: 90px;
  width: 100%;
  padding: 10px;
  font-family: sans-serif, Arial;
  border: 2px solid #eee;
  border-radius: 5px;
  font-weight: 100;
  cursor: pointer;
}

.radio-toolbar label img {
  height: 70px;
}

.radio-toolbar #basicBtn {
  height: 70px;
  width: 100%;
}

.radio-toolbar input[type='radio']:checked + label {
  border: 2px solid #000;
}

.radio-toolbar input[type='radio']:focus + label {
  border: 2px solid #000;
}

.chartModal {
  width: 100% !important;
}

.nameplate{
  /*display: block;*/
  position: relative;
  top: 70px;
  left: 0px;
  z-index: 1;
}

.nameplateMobile{
  display: none;
}

@media only screen and (max-width: 600px) {
  .radio-toolbar label {
    height: 100%;
  }
  .chart-img {
    max-width: 275px;
    margin: 0;
  }
  .nameplateMobile{
    display: block;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .nameplate{
    display: none;
  }*/
  .spacerDivWithLine
  {
    position: relative;
    background-color: red;
  }
  .column1{
    position: relative;
    top: 0px;
  }

  .column2{
    order: -1;
    left: 35px;
    position: relative;
  }
}
