.ringInput {
  display: flex;
  flex-flow: column;
  margin: 0.5rem 0;
}

@media screen and (min-width: 768px) {
  .ringInput {
    flex-grow: 1;
    width: calc(50% - 2rem);
    margin: 0.5rem 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .ringInput {
    flex-grow: 1;
    width: calc(33% - 2rem);
  }
}

.custom-tooltip .tooltip-inner {
  background-color: #fff;
  color: #dc3545;
  border: 1px solid #ced4da;
  padding: 8px 5px;
  display: flex;
}

.custom-tooltip .arrow::before {
  border-bottom-color: #ced4da !important;
  border-top-color: transparent !important;
  left: -76px;
}

.custom-tooltip .tooltip-icon {
  top: -10px;
  right: -10px;
  background-color: #ff8c00; 
  color: #ffffff; 
  width: 24px; 
  height: 24px; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
  border-radius: 3px;
}