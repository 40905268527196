.ringInputSection {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  justify-content: center;
  width: 100%;
}

.spacerDivWithLine {
  margin: 1rem 0;
  border-top: 1px solid #333;
  width: 100%;
}

.optionEngraving{
  margin-bottom: 10px;
}

.optionEngraving input{
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.optionEngraving label{
  margin-right: 30px;
}

.optionEngraving .invalid-feedback{
  margin-left: -18px;
}

.sectionHeader {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ringInputSection {
    flex-flow: row wrap;
    padding: 1.5rem;
    justify-content: space-evenly;
    width: 100%;
  }

  .artworkImg {
    max-width: 400px !important;
  }
}

@media screen and (max-width: 768px) {
}
