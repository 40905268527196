.Players {
  font-weight: bold;
  color: blue;
}
.Player {
  border-style: solid;
  border-width: thin;
  border-color: black;
  border-radius: 10rem;
  width: 50rem;
  padding-left: 2rem;
  text-align: left;
}

.playerCardButtons {
  position: relative;
  left: 10rem;
  top: 0rem;
}

.accordianRow {
  width: 100%;
  max-width: 1200px;
}

/*button {
  background-color: white;
  border-style: solid;
  border-radius: 25rem;
}*/
