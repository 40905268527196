.contentBody {
  /* width: 100%; */
  /* display: flex;
  flex-flow: column; */
  margin: 1rem auto;
}

.logo {
  max-width: 300px;
  margin: auto;
}

.loader {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1200px) {
  .contentBody {
    align-items: center;
  }
}

a.nav-link {
  color: black;
}

a.nav-link.active {
  background-color: #f7f7f7 !important;
}

.invalid-feedback {
  color: orange !important;
}
