#PackageConfirmationModal {
    line-height: 1.2em;
}

#PackageConfirmationModal .price .most-popular {
    background-color: #111;
    color: white;
    font-size: 12px;
    min-height: auto;
}

@media only screen and (min-width: 601px) {
    #PackageConfirmationModal .price .header {
    font-size: 22px;
    }

    #PackageConfirmationModal .long-table-row-titles {
    padding-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    #PackageConfirmationModal {
        padding: 3px;
    }

    #PackageConfirmationModal .long-table-row-titles {
    padding-top: 15%;
    font-size: 12px;
    }

    #PackageConfirmationModal .table-row-titles {
    font-size: 12px;
    }

    #PackageConfirmationModal .price .most-popular {
    font-size: 9px;
    font-weight: normal;
    }

    #PackageConfirmationModal .btn-primary {
    padding: 0 5px;
    font-size: 12px;
    min-height: 40px;
    }
}

#PackageConfirmationModal ul li {
    margin-bottom: 0px;
    font-weight: 600;
}

#PackageConfirmationModal .first-column {
    float: left;
    width: 19%;
    padding: 0;
}

#PackageConfirmationModal .columns {
    float: left;
    width: 20%;
    padding: 0;
}

#PackageConfirmationModal .half-columns {
    float: left;
    width: 50%;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    left: 25%;
}

#PackageConfirmationModal .price {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

#PackageConfirmationModal .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

#PackageConfirmationModal .price .header {
    background-color: #B12928;
    color: white;
    padding: 25px 0;
    display: block;
}

#PackageConfirmationModal .price li {
    border-bottom: 1px solid #eee;
    text-align: center;
    min-height: 60px;
}

#PackageConfirmationModal .price .grey {
    background-color: #eee;
    padding: 8px 0;
    min-height: auto;
}

#PackageConfirmationModal .checkmarks {
    padding-top: 20px;
    font-size: 20px;
    color: black;
}

#PackageConfirmationModal .table-row-titles {
    padding-top: 20px;
}